import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[11];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  We were impressed by Nixta Taqueria in so many ways that
                  extend past their amazing food. The offer a "Free Community
                  Fridge", where people can drop off fresh food, dry goods, and
                  essentially for the community. The staff is also heavily
                  involved with other aspects of social work. The restaurant
                  also makes themselves available to attend cultural events,
                  classes, and workshops. Head chef Edgar Rico has received
                  several awards and been featured in several books and
                  publications.
                </p>
                <p>
                  Nixta Taqueria offers some rare combinations that we loved. We
                  first tried the cauliflower taco that combines remesco, pine
                  nutz, queso freso, and cilantro. In our humble opinion, the
                  duck carnitas taco was the best we have found. It comes with
                  duck confit, salsa cruda, radish, and white onion. Simply
                  perfect. Coming in a close second, was the enchilada potosina
                  taco, which is potato and chorizo puree, duck fat refried
                  beans, queso enchilado, and tomato salsa.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
